.icon-text-box {
    height: 100%;
    text-align: center;
    border: 1px solid;
    border-radius: 0.25rem;
    padding: 2.25rem 0.625rem 1rem;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
    font-family: "Recife";
    font-size: 1rem;
    line-height: 150%;

    .icon-text-box-head {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: 1px;
        font-family: 'Valizas';
    }
}
