.hero-bar {
    width: 1.25rem;
    height: 0.1875rem;
    background-color: $color-b-200;
}

.hero-bar-light {
    @extend .hero-bar;
    background: $color-absolute-white !important;
}

.hero-bar-green {
    @extend .hero-bar;
    background: $color-f-300 !important;
}

.hero-link:first-child, .hero-link a span:first-child {
    display: table-cell;
}

.hero-subtitle {
    font-family: "Valizas";
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}