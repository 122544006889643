.template-clean {
    @include media-breakpoint-up(sm) {
        padding-top: 4rem;
    }

    @include media-breakpoint-up(md) {
        padding-top: 5rem;
    }

    [role="main"] {
        // main
        min-height: 400px;

        .bar {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 6.25vw;
            background-color: $black-c1c;

            img {
                padding: 1em 1em 1em 0;
            }

            h1, .h1 {
                font-family: 'Recife';
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                color: $white-fff;
                text-align: right;
            }

            .clean-date {
                font-family: 'Recife';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: $white-fff;
                text-align: right;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 3.125vw;
                padding-top: 7.8125vw;
                padding-bottom: 2vw;

                h1, p {
                    text-align: left;
                }
            }

            @include media-breakpoint-up(sm) {
                margin-bottom: 2rem;

                h1, .h1 {
                    padding: 1.5rem 0;
                }
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 3rem;

                h1, .h1 {
                    padding: 2rem 0;
                }
            }
        }

        p {
            font-family: 'Spezia';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}