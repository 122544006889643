.dao-modal {
  .modal-top {
    display: flow-root;

    .modal-title {
      @extend .text-4-semibold;
      clear: left;
      float: left;
      color: $color-n-600;
    }

    #dao-close-button {
      float: right;
      font-size: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      color: $color-i-700;
    }
  }

  .modal-middle p {
    @extend .text-2-semibold;
    color: $color-i-700;
  }

  .modal-buttons {

    .btn-primary * {
      color: $color-absolute-white;
    }

    .btn-secondary:hover * {
      color: $color-b-300;
    }

    a {
      width: 100%;
      border-radius: 4px;
      padding: 1.625rem 2.5rem 1.625rem 2rem;

      p {
        clear: left;
        float: left;
        text-align: left;
        padding-bottom: 0;
      }

      i {
        float: right;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }

      p:first-child {
        @extend .text-3-semibold;
      }

      p:last-child {
        @extend .text-5;
      }
    }
  }
}