/* BRAND COLOR SETS
  All of the color sets are listed from lightest to darkest.
  The correct naming covention is as follows:

  for scss ->
    $color-colorabbreviation-colorshade: hexcode
  for class name ->
    .frost-colortype-colorname-colorshade {csshere} //where colortype is background-color or color
  */

// Frost color calls
$color-frost-100: #3F526F;
$color-frost-200: #003057;

// absolute color calls
$color-absolute-white: #ffffff;
$color-absolute-black: #000000;

.color {
    &-frost {
        &-100 {
            color: $color-frost-100 !important;
        }

        &-200 {
            color: $color-frost-200 !important;
        }
    }

    &-absolute {
        &-white {
            color: $color-absolute-white !important;
        }

        &-black {
            color: $color-absolute-black !important;
        }
    }
}

.bkgcolor {
    &-frost {
        &-100 {
            background-color: $color-frost-100 !important;
        }

        &-200 {
            background-color: $color-frost-200 !important;
        }
    }

    &-absolute {
        &-white {
            background-color: $color-absolute-white !important;
        }

        &-black {
            background-color: $color-absolute-black !important;
        }
    }
}
