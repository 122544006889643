/*------------------------------------*\
    #ICON
\*------------------------------------*/

/**
 * 1) Small image that represents functionality
 */
.c-icon {
	height: 1rem;
	width: 1rem;
}
