.video-player_controls {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}
.MediaControls_defaultStyles {
    position: absolute;
    z-index: 10;
    bottom: 2rem;
    right: 2rem;
}
.MediaControls_buttonWrapper {
    display: flex;
    margin: 0;
    padding: 0;
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
}
.MediaControls_button {
    border-radius: 50%;
    height: 44px;
    outline: 0;
    padding: 0;
    width: 44px;
    transition: border-color .35s cubic-bezier(.76,0,.24,1),background-color .35s cubic-bezier(.76,0,.24,1);
    background-color: #fcfcfc;
    border: 1px solid #e4e2e1;
}
.MediaControls_button .MediaControls_icon {
    fill: #1C1C1C;
}
.MediaControls_button svg {
    margin: auto;
}
.MediaControls_button:hover {
    border-color: #32302f;
    background-color: #fcfcfc;
}
.MediaControls_container {
    position: absolute;
    display: block;
    pointer-events: auto;
}