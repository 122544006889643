//TODO: Refactor to make this code a different tile since there is more than one kind now

.tile-container {
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #D0D3D4;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    padding: 1.5em;

    .tile-contents { 
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .tile-banner-contents {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 769px) {
            flex-direction: row;

            .tile-left {
                width: 67%;
                display: flex;
                flex-direction: column;
            }
            .tile-right {
                width: 33%;
            }
        }

        @media (min-width: 577px) {
            flex-direction: row;

            .tile-left {
                width: 45%;
                display: flex;
                flex-direction: column;
            }
            .tile-right {
                width: 45%;
            }
        }

        @media (max-width: 567px) {
            flex-direction: column;

            .tile-left {
                width: 100%;
            }
            .tile-right {
                width: 100%;
            }
        }
    }
}

.tile-text {
    font-size: 14px;
    .tile-header {
        margin-bottom: 10px;
        font-weight: 800;
        color: $color-frost-200;
    }
}

.tile-image {
    text-align: center;
    margin-top: auto;
}

.tile-row {
    margin-bottom: 64px;
    margin-top: 8px;
}

.new-features-icon {
    img {
        margin-right: 5px;
        padding-bottom: 2px;
    }
    width: 55px;
    height: 26px;
    background-color: $color-f-200;
    border-radius: 20px;
    padding: 6px 6px;
    margin-bottom: 12px;
}

.feature-image {
    padding: 0px !important;
}

