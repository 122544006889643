.icon-btn {
    background-color: $color-a-500;
    color: $color-absolute-white;
    &:hover,
    &:hover:enabled {
        background-color: $color-b-300;
    }
    i, img {
        margin-right: 8px;
    }
}

.icon-btn-wrapper {
    align-content: center;
}