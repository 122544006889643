.promotilesbg {
  @include media-breakpoint-up(xs) {
    margin-top: 2vw;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 2rem;
  }
  @include media-breakpoint-up(md) {
    width: 100%;
    position: absolute;
    margin: auto;
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    z-index: 4;
    /* Original height is -38.5rem, increased to -60.3 for the anniversary tile*/
    /* margin-top: -38.5rem; */
    margin-top: -50.5rem;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .promotiles {
    /* padding: 2.2rem; */
    /* min-height: 43.125vw; */
    background-color: white;
    display: flex;
    text-align: center;
    .promotile {
      background-color: #fff;
      opacity: 0.9;
      .btn {
        white-space: pre-wrap;
        margin: 2px 0;
      }
      @include media-breakpoint-up(xs) {
        min-height: 34em;
      }
      @include media-breakpoint-up(sm) {
        min-height: 24.8rem;
      }
      @include media-breakpoint-up(md) {
        min-height: 20rem;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.pro-min {
  background-color: #fff;
  height: auto;
  .icon-image-content-main {
    height: 66.25vw;
    overflow: hidden;
  }
  .icon-image-content {
    height: auto;
  }
  .video-image-content {
    height: auto;
    word-break: break-word;
  }
  img {
    margin: auto;
    background-size: cover;
    image-rendering: -webkit-optimize-contrast;
    &.promo-image-text {
      height: 20rem;
    }
    &.promo-video {
      height: auto;
    }
    &.proicon {
      height: 31.25vw;
      width: 31.25vw;
    }
  }
  @include media-breakpoint-up(xs) {
    height: 43.125vw;
    .icon-image-content-main {
      height: 33.125vw;
      overflow: hidden;
    }
    .icon-image-content {
      height: auto;
    }
    .video-image-content {
      height: auto;
      word-break: break-word;
    }
    img {
      &.promo-image-text {
        height: auto;
      }
      &.promo-video {
        height: auto;
      }
      &.proicon {
        height: 15.625vw;
        width: 15.625vw;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    height: 48.8vw;
    .icon-image-content-main {
      height: 30.5rem;
      overflow: hidden;
    }
    .icon-image-content {
      height: 35.9375vw;
      overflow: auto;
    }
    .video-image-content {
      height: 6.5rem;
      overflow: auto;
      word-break: break-word;
    }
    img {
      &.promimg {
        height: 29rem;
        width: 19.3rem;
      }
      &.promo-image-text {
        height: 22.7rem;
      }
      &.promo-video {
        height: 23rem;
        width: 100%;
      }
      &.proicon {
        height: 14.4rem;
        width: 14.4rem;
      }
    }
  }
  @include media-breakpoint-up(md) {
    height: 24.1rem;
    .icon-image-content {
      height: 11.5rem;
      overflow-y: auto;
    }
    .icon-image-content-main {
      height: 23.4rem;
      overflow: hidden;
    }
    .video-image-content {
      height: 7rem;
      overflow: auto;
      word-break: break-word;
    }
    img {
      &.promimg {
        height: 22rem;
        width: 14.6rem;
      }
      &.promo-image-text {
        height: 17rem;
      }
      &.promo-video {
        height: 16rem;
        width: 100%;
      }
      &.proicon {
        height: 8rem;
        width: 8rem;
      }
    }
  }
  margin-top: 10px;
}

.promo-1 {
  background-color: #fff;
  margin-top: 10px;
  @include media-breakpoint-up(xs) {
    padding-top: 0.5vw;
  }
  @include media-breakpoint-up(sm) {
    padding-top: 0.7rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 1rem;
  }
}

body.migration-home{
  padding-top: 0 !important;
  #content {
    @include media-breakpoint-up(sm) {
      height: 50rem;
    }
    @include media-breakpoint-up(md) {
      height: 90rem;
    }
  }
  .wrap-page {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: local;
    @include media-breakpoint-up(xs) {
      /*  min-height: 168vw;*/
    }
    @include media-breakpoint-up(md) {
      background-attachment: fixed;
    }
    .page {
      padding-bottom: 0;
      [role='main'] {
        height: 76.5vw;
      }
    }
    .flat-info {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #fff;
      z-index: 1;
      p {
        margin: 0;
        padding: 3px 5px;
        a {
          font-weight: 600;
          padding-left: 1em;
        }
      }

      @media (min-width: 984px) {
        margin-bottom: 850px !important;
      }
    }
  }
  .btn {
    &.button {
      &.primary {
        &.dark {
          &.promotilebtn {
            background-color: #00263e;
            font-weight: bold;
            clear: both;
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.wrap-promo-tiles {
  margin-right: auto;
  margin-left: auto;
  /*padding-right: 22px;
    padding-left: 22px;*/
  max-width: 100%;
  @include media-breakpoint-up(xs) {
    max-width: 100%;
  }
  @include media-breakpoint-up(sm) {
    max-width: 768px;
  }
  @include media-breakpoint-up(md) {
    max-width: 984px;
  }
}
