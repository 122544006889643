/*------------------------------------*\
    #VISIBILITY CLASSES
\*------------------------------------*/

/**
 * Is Hidden
 * 1) Completely remove from the flow and screen readers.
 */
.is-hidden {
    display: none !important;
    visibility: hidden !important;
}