.wide-button-link {
  border: 1px solid $color-n-400;
  padding: 1.5rem;
  border-radius: 0.25rem;

  &:hover {
    border-color: $color-i-700;
  }

  &::before {
    content: '\f054';
    font-family: 'Font Awesome 6 Pro';
    float: right;
  }
}
