.accordion-button {
  border-bottom: 1px solid $color-n-300;
  color: $color-frost-200;
  &:not(.collapsed) {
    color: $color-a-500 !important;
    font-weight: bold;
    background-color: $color-absolute-white;
  }
} 


.accordion-collapse {
  @media print {
    display: block !important;
  }
}

.accordion {

  .accordion-item {
    border: none;
  }
  .accordion-body {
    .disclosure-qa:not(:last-child){
      padding-bottom: 2rem;
    }
  }

  button.view-all-button {
    &.collapsed:after{
        content: "\f107";
        font-family: "Font Awesome 6 Pro";
        padding-left: 8px;
    }
      
    &.expanded:after {
      content: "\f106";
      font-family: "Font Awesome 6 Pro";
      padding-left: 8px;
    }
  }
}