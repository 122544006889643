/*------------------------------------*\
    #Bootstrap variable overrides

    Used in customizing bootstrap
\*------------------------------------*/

$navbar-nav-link-padding-x: 1rem;  
$navbar-nav-link-padding-y: 0.5rem;  
$line-height-base: 1.25;

$spacer: 1rem;
$spacers: ();
$spacers: (
  0: 0,
  4: (
    $spacer * 0.25,
  ),
  8: (
    $spacer * 0.5,
  ),
  12: (
    $spacer * 0.75,
  ),
  16: $spacer,
  20: (
    $spacer * 1.25,
  ),
  24: (
    $spacer * 1.5,
  ),
  28: (
    $spacer * 1.75,
  ),
  32: (
    $spacer * 2,
  ),
  36: (
    $spacer * 2.25,
  ),
  40: (
    $spacer * 2.5,
  ),
  44: (
    $spacer * 2.75,
  ),
  48: (
    $spacer * 3,
  ),
  52: (
    $spacer * 3.25,
  ),
  56: (
    $spacer * 3.5,
  ),
  60: (
    $spacer * 3.75,
  ),
  64: (
    $spacer * 4,
  ),
  68: (
    $spacer * 4.25,
  ),
  72: (
    $spacer * 4.5,
  ),
  76: (
    $spacer * 4.75,
  ),
  80: (
    $spacer * 5,
  ),
  84: (
    $spacer * 5.25,
  ),
  88: (
    $spacer * 5.5,
  ),
  92: (
    $spacer * 5.75,
  ),
  96: (
    $spacer * 6,
  ),
  100: (
    $spacer * 6.25,
  ),
  104: (
    $spacer * 6.5,
  ),
  108: (
    $spacer * 6.75,
  ),
  112: (
    $spacer * 7,
  ),
  116: (
    $spacer * 7.25,
  ),
  120: (
    $spacer * 7.5,
  ),
  124: (
    $spacer * 7.75,
  ),
  128: (
    $spacer * 8,
  ),
  132: (
    $spacer * 8.25,
  ),
  136: (
    $spacer * 8.5,
  ),
  140: (
    $spacer * 8.75,
  ),
  144: (
    $spacer * 9,
  ),
  148: (
    $spacer * 9.25,
  ),
  152: (
    $spacer * 9.5,
  ),
  156: (
    $spacer * 9.75,
  ),
  160: (
    $spacer * 10,
  ),
  164: (
    $spacer * 10.25,
  ),
  168: (
    $spacer * 10.5,
  ),
);