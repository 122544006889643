.full-promotile {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white-fff;
    border: 1.5px solid #EEE;
    border-radius: 4px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.17);

    .promotile-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1.5em;

        .promotile-link {
            font-family: 'Spezia Extended';
            font-size: 16px;
            font-weight: 500;
        }
    }
}
