.features-carousel{
  .text-start {
    @include media-breakpoint-down(md) {
      padding-bottom: 3rem;
    }
  }
  .swiper-controls {
    @include media-breakpoint-up(md) {
      float: left;
      height: 50%;
      align-items: flex-end;
    }
    .swiper-button-prev {
      &.swiper-button-disabled {
        // content: url(/images/icons/carousel/Left-Inactive.svg);
      }
      // content: url(/images/icons/carousel/Left-Active.svg);
      margin-right: .5rem;
    }
    .swiper-button-next {
      &.swiper-button-disabled {
        // content: url(/images/icons/carousel/Right-Inactive.svg);
      }
      margin-left: .5rem;
      // content: url(/images/icons/carousel/Right-Active.svg);
    }
  }
}