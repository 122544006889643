@use 'sass:color';

.banner {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    padding: 1rem;

    &.banner-primary {
        color: $color-b-400;
        background-color: $color-a-100;
        border: 1px solid $color-b-400;
    }
    &.banner-secondary {
        color: $color-n-600;
        background-color: $color-n-100;
        border: 1px solid $color-n-500;
    }
    &.banner-success {
        color: $color-g-600;
        background-color: $green-fdc;
        border: 1px solid $color-g-600;
    }
    &.banner-warning {
        color: $color-y-600;
        background-color: $color-y-100;
        border: 1px solid $color-y-600;
    }
    &.banner-danger {
        color: $color-r-600;
        background-color: $color-p-100;
        border: 1px solid $color-r-600;
    }

    i {
        height: 1rem;
        width: 1rem;
    }

    span {
        font-size: 0.875rem;
        color: $color-n-600;
    }
}