footer { 
  .static-footer {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    span {
      font-weight: 700;
      font-size: 0.875rem;
    }
    
    a {
      color: $color-i-700;
      text-decoration: none;
      
      &:visited {
        color: $color-i-700;
      }
    }

    .static-footer-top a{
      padding-bottom: 1rem;
    }

    .static-footer-bottom {
      margin-top: 2rem;
    }
  }
}