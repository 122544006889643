.testimonials {

  .testimonials-title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.25rem;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }

  .testimonials-text {
    font-size: 32px;
    line-height: 125%;
  }

  .testimonials-quote {
    color: $color-f-100;
    // width: 26px;
    // height: 21px;
    font-size: 164px;
    margin-bottom: -6rem;
    margin-top: -40.5px;
  }

  .testimonials-wrapper {
    text-align: left;
    padding: 2.625rem 3rem;

    @include media-breakpoint-down(md) {
      padding: 2rem 1.5rem;
    }
  }

  //Unique buttons styles for the green background of testimonials

  .swiper-controls {
    .swiper-button-prev,
    .swiper-button-next {
      color: $color-absolute-white;
      &.swiper-button-disabled {
        color: $color-f-500;
      }
    }
  
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: $color-f-500;
      }
  
      .swiper-pagination-bullet-active {
        background-color: $color-absolute-white;
      }
    }
  }

}