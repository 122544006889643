a {
    &:focus {
        box-shadow: none;
    }

    &:focus-visible>* {
        outline: none;
        border-radius: 2%;
        box-shadow: 0 0 0 0.13rem $color-a-600;
        opacity: 1 !important;
    }

    &:hover>* {
        box-shadow: none !important;
    }
}

/** Skip Main **/

#content:focus {
    outline: none;
  }
  
  a.skip-main {
    background: $color-absolute-white;
    height: 2.8125reem;
    left: 50%;
    padding: 0.5rem;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index:999;
  }
  
  a.skip-main:focus {
    transform: translateY(0%);
  }