/*------------------------------------*\
#PRIMARY NAVIGATION
\*------------------------------------*/
a.skip-main {
  background: $color-absolute-white;
  height: 2.8125rem;
  left: 50%;
  padding: 0.5rem;
  position: absolute;
  opacity: 0;
  transform: translateY(-300%);
  transition: transform 0.3s;
  z-index: 999;

  &:focus {
    transform: translateY(0%);
    opacity: 1;
  }
}

/*------------------------------------*\
#DESKTOP NAVIGATION
\*------------------------------------*/

.top-nav {
  // display: flex;
  height: 5.625rem;

  .nav-left {
    flex-grow: 1;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(xl) {
      margin-left: 8.33333%;
    }
  }

  .nav-right {
    display: flex;
    .nav-link {
      font-size: 1em;
      font-weight: bold;
    }
    .nav-link-right {
      font-size: 1em;
      line-height: 1.25em;
      text-decoration: none !important;
      white-space: nowrap;
      align-self: center;
      padding: 0.5rem 1rem;
      margin-right: 1em;
      height: 2.5em;
    }

    #login-button {
      text-decoration: none;
    } 

    #nav-right-open-acc {
      margin-right: 0px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 8.33333%;
    }
  }

  .nav-link {
    & span {
      font-size: 1em;
      line-height: 1.25em;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;
      align-self: center;
      padding: 0.5rem 1rem;

      &:hover {
        padding-bottom: 1.71rem;
      }
    }

    &.active > span {
      padding-bottom: 2.125rem;
    }
  }

  .search {
    border: none;
    padding-right: 1.7rem;
    &:focus,
    &:active {
      outline: 3px solid $color-b-300 !important;
      border-radius: 0.25rem;
    }
  }

  .nav-tabs {
    button[role='tab'] {
      &.active {
        border-bottom: 0.25rem solid $color-b-300;
        color: $color-b-300;
        margin-top: 0.25rem;
      }
      border: none;
      border-bottom: 1px solid $color-n-100;
      font-size: 1.125rem;
      font-synthesis: 1.5rem;
      width: 50%;
      background: none;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      text-align: center;
    }
  }

  .tab-content {
    > .frostOnlineForm {
      margin: 3rem 4rem;

      .register {
        padding-top: 1.875rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
      }

      .forgotPassword {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
        position: absolute;
        margin-right: 4rem;
        right: 0;
        top: 44%;
      }
      .form-group {
        padding-bottom: 1rem;
      }

      .form-label {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: $color-i-700;
        display: block;
        position: relative;
        padding-bottom: 0.75rem;
      }

      input {
        border: 1px solid $color-n-400;
        width: 100%;
        height: 2.5rem;
      }
    }

    > .otherLoginsLinks {
      margin: 2rem 3rem 4rem;
      .list-group {
        columns: 2;
        column-gap: 2rem;
        list-style: none;
        > .list-group-item {
          &:visited {
            color: $color-n-600;
          }

          &:hover {
            color: $color-b-300;
          }

          color: $color-n-600;
          display: block;
          white-space: normal;
          margin-bottom: 0.75rem;
          font-size: 1rem;
          line-height: 1.5rem;
          text-decoration: none;
        }
      }
    }
  }

  hr {
    color: $color-n-300;
    width: 100vw;
    height: 1px;
    margin: 0;
  }

  .dropdown-menu {
    box-shadow: 0px 8px 22px -2px rgba(0, 0, 0, 0.08);
    background-color: $color-absolute-white;
    border: none;
    width: 100%;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;

    &.page-dropdown {
      lh a {
        display: block;
        font-weight: bold;
        padding-bottom: .5rem;
      }

      .dropdown-pagelink-header {
        @extend .text-preset-5;
        font-size: 1rem;
        line-height: 24px;
        font-weight: 600;
        list-style: none;
        padding: .5rem 0;
        border-bottom: 1px solid #E0E0E0;
        a {

          /** Small hack, pseudo element to set sizing of flex container to BOLD size, to avoid items shifting when hovering **/
          &:before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          &:hover {
            font-weight: bold;
          }

          padding: .5rem 0;
          text-decoration: none;
        }
      }

      .dropdown-pagelink {
        @extend .text-preset-5;
        line-height: 22px;
        font-size: 0.875rem;
        font-size: 1rem;
        list-style: none;
        padding: .5rem 0;
        a {

          /** Small hack, pseudo element to set sizing of flex container to BOLD size, to avoid items shifting when hovering **/
          &:before {
            display: block;
            content: attr(title);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }

          &:hover {
            font-weight: bold;
          }

          padding: .5rem 0;
          text-decoration: none;
        }
      }
    }

    &.login-dropdown {
      padding: 0;
      width: 446px;
      height: 496px;
    }

    .container {
      padding: 2.5rem;
    }
  }

  .dropdown-toggle:after {
    content: none;
  }
  .dropdown-toggle.show > span {
    border-bottom: 3px solid $color-b-300;
    padding-bottom: 1.71rem;
  }
}

/*------------------------------------*\
#Mobile Navigation Menu
\*------------------------------------*/

.mobile-nav-menu {
  position: absolute;
  left: -100vw;
  top: 0;
  height: auto;
  padding-bottom: 3rem;
  width: 100vw;
  transition: 0.3s;
  background-color: $color-absolute-white;
  z-index: 200;

  hr {
    margin: 2.5rem 0;
  }

  .mobile-menu-banner {
    background-color: $black-c1c;
    height: 5.625rem;
    padding-left: 2rem;
    padding-right: 2rem;

    .close {
      color: $color-absolute-white;
      font-size: 1rem;
    }
  }

  .mobile-menu-search {
    
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid $color-n-400;

    .search {
      width: 100%;
      border-radius: 0.25rem;
      box-sizing: border-box;
      border: 0.0625rem solid #686869;
      font-size: 1.125rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      padding-left: 1rem;
      padding-right: 2rem;
      height: 2.8125rem;
    }

    .searchIcon {
      left: -1.5rem;
      position: relative;
      z-index: 100;
      height: auto;
      width: 0;
    }
  }

  ul {
    padding: 0;
  }

  .mobile-nav-link {
    a {
      display: block;
      list-style: none;
      padding: 1.125rem 0 1.125rem 1.5rem;
    }
  }

  li.mobile-nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .accordion-body {
    padding: 0;
    a {

      &:hover {
        background-color: $color-n-300;
      }
      display: block;
      padding: 1.125rem 0 1.125rem 1.5rem;
      list-style: none;
      border-bottom: 1px solid $color-n-200;
    }
    ul li {
      padding: 0;
    }
  }

  .accordion-collapse {
    background-color: $color-n-100;
  }

  li {
    list-style: none;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    border-bottom: 1px solid $color-n-200;

    &:first-child {
      border-top: 1px solid $color-n-200;
    }

    a,span {
      font-size: 1rem;
      line-height: 1.1875rem;
      color: $black-c1c;
      text-decoration: none;

      &:hover {
        font-weight: bold;
      }
      &:active {
        font-weight: bold;
      }
    }

    &.accordion-button {
      border-top: none;
      border-right: none;
      border-left: none;
      padding-left: 1.5rem;
      color: $color-frost-200;
    }

    // &.dropdown-button {


    //   &:after {
    //     content: '\f054';
    //     right: 100%;
    //     font-family: "Font Awesome 5 Pro";
    //     position: absolute;
    //     right: 8%;
    //   }
    // }
  }

  .mobile-menu-buttons {
    margin-top: 2rem;
    button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &.show {
    left: 0;
  }
}

#mobilePersonalAccordion, #mobileBusinessAccordion, #mobileAboutFrostAccordion {
  padding-left: 1rem;
  padding-right: 1rem;
}
