/** DEPRECATED
  * Once the entire site is migrated to aurora,
  * this class which overrides the old footer styles can be removed
  * or refactored
  **/
footer {
  .footer-locations {
    padding-bottom: 1rem;
    a {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;
      text-decoration: none;
    }

  }

  ul {
    padding: 0;
    margin-bottom: 1.5rem;
  }

  .header {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;

    a {
      color: $color-i-700;
      text-decoration: none;

      &:visited {
        color: $color-i-700;
      }
    }
  }

  li {
    list-style-type: none;

    &:first-child {

      span,
      p {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
      }

      padding-bottom: 0.5625rem;
    }

    a,
    a:visited {
      text-decoration: none;
    }

    padding: 0;
    margin: 0;
    color: $color-i-700;
    font-size: 1rem;
    line-height: 1.5rem;

  }

  .disclosure {
    font-size: 0.875rem;
    color: $color-n-600;

    p,
    span,
    a {
      font-size: 0.875rem;
    }
  }
}
