.text-carousel{
  position: relative;

  .swiper-slide {
    position: relative;
    height: auto;
  }
  
  .slide-caption {
    position: absolute;
    bottom: -3.875rem;

    p {
      margin-bottom: 0;
    }
  }
}

.swiper-container-text {
  .swiper-controls {
    @include media-breakpoint-up(md) {
      float: left;
      height: 50%;
      align-items: flex-end;
    }
  }
}