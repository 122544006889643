.link-pill,
.link-pill.light-theme {
  border-radius: 3.3125rem;
  padding: 0.75rem 1rem;
  margin-right: 1rem;
  line-height: 4rem;
  display: inline-flex;
  margin-bottom: 1rem;
  @extend .no-decor;

  &.link-pill-arrow {
    span:after {
      content: "\f061";
      font-family: 'Font Awesome 6 Pro';
      transform: rotate(45deg);
      display: inline-block;
      padding-left: 0.5rem;
    }
  }

  &.link-pill-search {
    span:before {
      content: "\f002";
      font-family: 'Font Awesome 6 Pro';
      display: inline-block;
      padding-right: 0.5rem;
    }
  }

  >span {
    align-items: center;
    font-size: 1rem;
    line-height: 150%;
    white-space: nowrap;
  }
}

.dark-theme .link-pill,
.link-pill.dark-theme {
  border: 1px solid $color-n-400;
  span {
    color: $color-n-400;
  }
}