.text {

    &-1,
    &-2,
    &-3,
    &-4,
    &-5,
    &-6 {
        font-weight: normal;

        &-semibold,
        strong {
            font-weight: 600;
        }
    }

    &-1,
    &-1-semibold {
        font-size: 3.25rem;
        line-height: 16rem;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
            line-height: 2.25rem;
        }
    }

    &-2,
    &-2-semibold {
        font-size: 2rem;
        line-height: 2.25rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    &-3,
    &-3-semibold {
        font-size: 1.5rem;
        line-height: 1.75rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    &-4,
    &-4-semibold {
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 0.09327rem;
        text-transform: uppercase;
    }

    &-5,
    &-5-semibold {
        font-size: 1.125rem;
        line-height: 1.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }

    &-6,
    &-6-semibold {
        font-size: 0.875rem;
        line-height: 1.125rem;
    }
}

strong {
    .text- {

        &-1,
        &-2,
        &-3,
        &-4,
        &-5,
        &-6 {
            font-weight: 700;
        }
    }
}

.font {
    &-50 {
        font-size: 50%;
    }

    &-75 {
        font-size: 75%;
    }

}