.template-2[role='main'] {
    float: left;
    width: 75%;

    img {
        max-width: 100%;
        height: auto;
    }

    .h5 {
        @extend .text-preset-7-semibold;
    }

    table.rtable {
        @extend .two-header-table;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

aside[role='complementary'] {
    float: left;
    width: 25%;

    .cta {
        padding: 1rem;
        border-radius: 0.25rem;
        margin-bottom: 1rem;

        &.cross-sell {
            background-color: $color-n-200;
        }

        &.talk-to-advisor {
            background-color: $color-a-100;
        }
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        margin-top: 2em;
    }
}