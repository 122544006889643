.btn {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  border-radius: .25rem;
  font-weight: bold;
  text-decoration: none;

  &.btn-rounded {
    border-radius: 30px;
    min-height: 2.25rem;
  }


  &.btn-small {
    width: 6.25rem;
    min-height: 2.75rem;
  }

  &.btn-medium {
    min-height: 2.75rem;
    width: auto;
  }

  &.btn-large {
    min-height: 3.125rem;
    width: auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &.btn-fixed {
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}