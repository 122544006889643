.button-image-container {
    &.col-12, &.col-md-6 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }
}

.button-container {
    display: flex;
    flex-direction: column;
}
.wide-button.active {
    background-color: #D7F0FB;
}
.image-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tech-image {
    max-width: 100%;
    height: auto;
    margin: 10px auto;
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .btn {
        margin-bottom: 10px;
    }

    .button-image-container {
        &.col-12, &.col-md-6 {
            flex: 0 0 100%;
        }
    }

    .image-container {
        margin-top: 10px;
    }
}