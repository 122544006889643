.thumbnails {
    swiper-slide,
    .swiper-slide-next{
        opacity: 0.5;

    }

    .swiper-slide-thumb-active {
        opacity: 1.0;
    }
    
}