.container,
.container-fluid {

    @include media-breakpoint-down(md) {
        // Override the container size at sm & md sizes
        max-width: unset !important;
    }

    &.no-bumpers {
        @include media-breakpoint-down(md) {

            .section-wrapper {
                padding-left: 0;
                padding-right: 0;
            }

            width: 100%;
            max-width: none;
        }
    }
}

.wrapping-container {
    padding-left: 0;
    padding-right: 0;
}

.text-break{
    word-wrap: break-word;
    height: 100%;
}
