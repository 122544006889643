.personal-checking-hero {
  background-color: $color-f-700;

  .hero-content {
    color: $color-absolute-white;
    vertical-align: middle;

    h1,p {
      color: $color-absolute-white;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .hero-image {
    top: 4rem;
    position: relative;
    img {
      box-shadow: 11px 37px 93px 0px rgb(46 46 46 / 37%);
    }

    @include media-breakpoint-down(md) {
      top: 2rem;
    }
  }
}

.swiper-hero-container{
  @extend .swiper-container;
  overflow: visible;

  .swiper-slide {
    background: none;
  }
}