.sitemap {
    :before { 
        display: none; 
    }

    .header-5 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        color: #1C1C1C;
        line-height: 1.75em;
    }

    ul {
        list-style: none;
        border-left: 2px solid #f1f4f6;
    }
}

