#search-results {
    .knowledge-result {
        border: 1px solid #E0E0E0;
        margin-top: 1em;
        margin-bottom: 2em;

        .knowledge-title {
            .result-title {
                padding: 1em;
                font-weight: 600;
                margin-bottom: 0;
                text-decoration: none;
            }
        }

        .knowledge-detail {
            padding: 0em 1em;
            display: inline-block;
            margin-bottom: 0;
            text-decoration: none;
        }
    }

    .results-found:not(:first-child) {
        padding-top: 2em;
    }

    .results-found {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-bottom: 1em;

        .results-icon {
            .fa-border {
                padding: 0.5em;
                border: 0.5px solid #7AC2EA;
                border-radius: 4px;
                background-color: #D7F0FB;
                color: #1674DB;
            }
        }

        .results-list {
            margin-left: 0.5em;
            font-weight: 700;
        }
    }

    .search-result {
        padding-bottom: 1.5rem;

        .result-title {
            font-weight: 600;
            margin-bottom: 0;
        }

        .result-description {
            display: inline-block;
            margin-bottom: 0;
            text-decoration: none;
        }
    }

    .zipcode-result {
        margin-bottom: 3rem;

        .location-search-zipcode-link {
            font-size: 2rem;
        }
    }
   
}