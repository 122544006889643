.icon-tile,
.icon-tile.light-theme {
    display: flex;
    flex-direction: column;
    background-color: $color-absolute-white;
    height: 100%;
    border: 1px solid $color-n-400;
    border-radius: 0.25rem;
    padding: 1.5rem;
    margin: 1rem 0rem;

    &:hover {
        border-color: $color-i-700;
    }

    img {
        width: 15%;
    }

    .tile-image {
        width: 3rem;
        height: 3rem;
        border: 1px solid $color-a-300;
        border-radius: 0.25rem;
        background-color: $color-a-100;
        color: $color-a-300;
        margin-bottom: 1.5rem;

        i {
            font-size: 1.6875rem;
            line-height: 2rem;
            padding: 0.5rem;
        }

        img {
            margin-top: 0.5625rem;
            height: 1.875rem;
            width: 1.875rem;
        }
    }
}

.icon-tile.dark-theme {
    .tile-image {
        color: $color-absolute-white;
    }
}

.icon-tile-link {
    position: absolute;
    bottom: 0;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
}