.bullet {
  .bullet-text.large{
    margin-left: 2rem;
    
    @include media-breakpoint-down(md) {
      margin-left: 1rem;
    }    
  }

  .bullet-text.bullet-small{
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
}