.p-mb-0 {
  p {
    margin-bottom: 0;
  }
}

.rotate {
    &-10 {
        rotate: 10deg;
    }
    &-20 {
        rotate: 20deg;
    }
    &-30 {
        rotate: 30deg;
    }
    &-45 {
        rotate: 45deg;
    }
    &-60 {
        rotate: 60deg;
    }
    &-90 {
        rotate: 90deg;
    }
    &-135 {
        rotate: 135deg;
    }
    &-180 {
        rotate: 180deg;
    }
    &-225 {
        rotate: 225deg;
    }
    &-270 {
        rotate: 270deg;
    }
    &-315 {
        rotate: 315deg;
    }
}