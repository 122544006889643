.search-modal {
  top: -20%;

  .form-group {
    padding-bottom: 1rem;
  }

  .form-label {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: $color-i-700;
    display: block;
    position: relative;
    padding-bottom: 0.75rem;
  }

  input {
    border: 1px solid $color-n-400;
    width: 100%;
    height: 2.5rem;
  }

  .modal-content {
    border: none !important;
    border-radius: 0 !important;

    i:hover {
      color: #1F72BB;
    }
  }

  .modal-dialog {
    max-width: none !important;
    margin: 0 !important;
  }


  .search-bar {
    
    &:hover {
      border-bottom-color: #1F96FF;
    }
  }

  .nav-search {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #667a85;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    color: #667a85;
    padding-right: 1rem;

    &:focus {
      border-bottom: 2px solid #667a85;
    }
  }

  .magGlassIcon {
    font-weight: lighter;
    align-self: center;
    position: relative;
    right: 1rem;
  }

  &.fade {

    opacity: 1 !important;
    transition: .5s !important;

    &.show {
      transform: translate(0, 20%) !important;
      -webkit-animation: slide-down .5s ease-out;
      -moz-animation: slide-down .5s ease-out;
      animation: slide-down .5s ease-out;
    }
  }
}
