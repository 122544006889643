/* PRIMARY COLOR SETS
  All of the color sets are listed from lightest to darkest.
  The correct naming covention is as follows:

  for scss ->
    $color-colorabbreviation-colorshade: hexcode
  for class name ->
    .frost-colortype-colorname-colorshade {csshere} //where colortype is background-color or color
  */

// neutral color calls
$color-n-100: #FAFAFA;
$color-n-200: #F5F5F5;
$color-n-300: #EEEEEE;
$color-n-400: #E0E0E0;
$color-n-500: #9E9E9E;
$color-n-600: #686869;
$color-n-700: #292929;
$color-n-800: #1C1C1F;
$color-n-900: #141417;
$color-n-1000: #0F0F12;

// indigo color calls
$color-i-100: #EFEBE6;
$color-i-200: #D0D3D4;
$color-i-300: #C1C6C8;
$color-i-400: #A2AAAD;
$color-i-500: #7C878E;
$color-i-600: #586770;
$color-i-700: #333F48;
$color-i-800: #1D252D;

// blue color calls
$color-b-100: #57B9FF;
$color-b-200: #1F96FF;
$color-b-300: #1674DB;
$color-b-400: #0F4286;

// green color calls
$color-g-100: #DAF8D0;
$color-g-200: #B0F2A4;
$color-g-300: #77D971;
$color-g-400: #48B44B;
$color-g-500: #1A8227;
$color-g-600: #136F27;
$color-g-700: #0D5D26;

// red color calls
$color-r-100: #FCCBB3;
$color-r-200: #F7A68C;
$color-r-300: #EF836E;
$color-r-400: #E54E40;
$color-r-500: #C42F2E;
$color-r-600: #A4202A;
$color-r-700: #841426;

// 2024 design refresh - lighter background colors for dark text
$red-f48: #F26F48;
$red-f3d: #F15F3D;
$yellow-415: #FCB415;
$yellow-10e: #FFC10E;
$green-652: #69A652;
$green-150: #77B150;
$green-fdc: #E4EFDC; // green-150 but 40% lighter
$blue-c8f: #3E9C8F;
$blue-09a: #00909A;
$blue-7d5: #00B7D5;
$blue-bc5: #00ABC5;

// 2024 design refresh - darker background colors for light text
$green-420: #428420;
$purple-88b: #8A488B;
$blue-099: #008099;
$red-336: #C35336;
$blue-669: #0E5669;
$red-322: #BC4322;
$green-d2a: #374D2A;
$purple-c43: #431C43;
$green-742: #557742;
$purple-f70: #6F3F70;

//2024 design refresh - neutral colors
$white-fff: #FFFFFF;
$white-1ee: #F3F1EE;
$black-c1c: #1C1C1C;

.color {
    &-n {
        &-100 {
            color: $color-n-100 !important;
        }

        &-200 {
            color: $color-n-200 !important;
        }

        &-300 {
            color: $color-n-300 !important;
        }

        &-400 {
            color: $color-n-400 !important;
        }

        &-500 {
            color: $color-n-500 !important;
        }

        &-600 {
            color: $color-n-600 !important;
        }

        &-700 {
            color: $color-n-700 !important;
        }

        &-800 {
            color: $color-n-800 !important;
        }

        &-900 {
            color: $color-n-900 !important;
        }

        &-1000 {
            color: $color-n-1000;
        }
    }

    &-i {
        &-100 {
            color: $color-i-100 !important;
        }

        &-200 {
            color: $color-i-200 !important;
        }

        &-300 {
            color: $color-i-300 !important;
        }

        &-400 {
            color: $color-i-400 !important;
        }

        &-500 {
            color: $color-i-500 !important;
        }

        &-600 {
            color: $color-i-600 !important;
        }

        &-700 {
            color: $color-i-700 !important;
        }

        &-800 {
            color: $color-i-800 !important;
        }
    }

    &-b {
        &-100 {
            color: $color-b-100 !important;
        }

        &-200 {
            color: $color-b-200 !important;
        }

        &-300 {
            color: $color-b-300 !important;
        }

        &-400 {
            color: $color-b-400 !important;
        }
    }

    &-g {
        &-100 {
            color: $color-g-100 !important;
        }

        &-200 {
            color: $color-g-200 !important;
        }

        &-300 {
            color: $color-g-300 !important;
        }

        &-400 {
            color: $color-g-400 !important;
        }

        &-500 {
            color: $color-g-500 !important;
        }

        &-600 {
            color: $color-g-600 !important;
        }

        &-700 {
            color: $color-g-700 !important;
        }
    }

    &-r {
        &-100 {
            color: $color-r-100 !important;
        }

        &-200 {
            color: $color-r-200 !important;
        }

        &-300 {
            color: $color-r-300 !important;
        }

        &-400 {
            color: $color-r-400 !important;
        }

        &-500 {
            color: $color-r-500 !important;
        }

        &-600 {
            color: $color-r-600 !important;
        }

        &-700 {
            color: $color-r-700 !important;
        }
    }

    &-red {
        &-f48 {
            color: $red-f48 !important;
        }

        &-f3d {
            color: $red-f3d !important;
        }

        &-336 {
            color: $red-336 !important;
        }

        &-322 {
            color: $red-322 !important;
        }

    }

    &-yellow {
        &-415 {
            color: $yellow-415 !important;
        }

        &-10e {
            color: $yellow-10e !important;
        }

    }

    &-green {
        &-652 {
            color: $green-652 !important;
        }

        &-150 {
            color: $green-150 !important;
        }

        &-fdc {
            color: $green-fdc !important;
        }

        &-420 {
            color: $green-420 !important;
        }

        &-742 {
            color: $green-742 !important;
        }

        &-d2a {
            color: $green-d2a !important;
        }

    }

    &-blue {
        &-c8f {
            color: $blue-c8f !important;
        }

        &-7d5 {
            color: $blue-7d5 !important;
        }

        &-09a {
            color: $blue-09a !important;
        }

        &-bc5 {
            color: $blue-bc5 !important;
        }

        &-099 {
            color: $blue-099 !important;
        }

        &-669 {
            color: $blue-669 !important;
        }

    }

    &-purple,
    &-grimace {
        &-88b {
            color: $purple-88b !important;
        }

        &-c43 {
            color: $purple-c43 !important;
        }

        &-f70 {
            color: $purple-f70 !important;
        }

    }

    &-white {
        &-1ee {
            color: $white-1ee !important;
        }

        &-fff {
            color: $white-fff !important;
        }

    }

    &-black {
        &-c1c {
            color: $black-c1c !important;
        }

    }


}



.bkgcolor {
    &-n {
        &-100 {
            background-color: $color-n-100 !important;
        }

        &-200 {
            background-color: $color-n-200 !important;
        }

        &-300 {
            background-color: $color-n-300 !important;
        }

        &-400 {
            background-color: $color-n-400 !important;
        }

        &-500 {
            background-color: $color-n-500 !important;
        }

        &-600 {
            background-color: $color-n-600 !important;
        }

        &-700 {
            background-color: $color-n-700 !important;
        }

        &-800 {
            background-color: $color-n-800 !important;
        }

        &-900 {
            background-color: $color-n-900 !important;
        }

        &-1000 {
            background-color: $color-n-1000;
        }
    }

    &-i {
        &-100 {
            background-color: $color-i-100 !important;
        }

        &-200 {
            background-color: $color-i-200 !important;
        }

        &-300 {
            background-color: $color-i-300 !important;
        }

        &-400 {
            background-color: $color-i-400 !important;
        }

        &-500 {
            background-color: $color-i-500 !important;
        }

        &-600 {
            background-color: $color-i-600 !important;
        }

        &-700 {
            background-color: $color-i-700 !important;
        }

        &-800 {
            background-color: $color-i-800 !important;
        }
    }

    &-b {
        &-100 {
            background-color: $color-b-100 !important;
        }

        &-200 {
            background-color: $color-b-200 !important;
        }

        &-300 {
            background-color: $color-b-300 !important;
        }

        &-400 {
            background-color: $color-b-400 !important;
        }
    }

    &-g {
        &-100 {
            background-color: $color-g-100 !important;
        }

        &-200 {
            background-color: $color-g-200 !important;
        }

        &-300 {
            background-color: $color-g-300 !important;
        }

        &-400 {
            background-color: $color-g-400 !important;
        }

        &-500 {
            background-color: $color-g-500 !important;
        }

        &-600 {
            background-color: $color-g-600 !important;
        }

        &-700 {
            background-color: $color-g-700 !important;
        }
    }

    &-r {
        &-100 {
            background-color: $color-r-100 !important;
        }

        &-200 {
            background-color: $color-r-200 !important;
        }

        &-300 {
            background-color: $color-r-300 !important;
        }

        &-400 {
            background-color: $color-r-400 !important;
        }

        &-500 {
            background-color: $color-r-500 !important;
        }

        &-600 {
            background-color: $color-r-600 !important;
        }

        &-700 {
            background-color: $color-r-700 !important;
        }
    }

    &-red {
        &-f48 {
            background-color: $red-f48 !important;
        }

        &-f3d {
            background-color: $red-f3d !important;
        }

        &-336 {
            background-color: $red-336 !important;
        }

        &-322 {
            background-color: $red-322 !important;
        }

    }

    &-yellow {
        &-415 {
            background-color: $yellow-415 !important;
        }

        &-10e {
            background-color: $yellow-10e !important;
        }

    }

    &-green {
        &-652 {
            background-color: $green-652 !important;
        }

        &-150 {
            background-color: $green-150 !important;
        }

        &-420 {
            background-color: $green-420 !important;
        }

        &-742 {
            background-color: $green-742 !important;
        }

        &-d2a {
            background-color: $green-d2a !important;
        }

    }

    &-blue {
        &-c8f {
            background-color: $blue-c8f !important;
        }

        &-7d5 {
            background-color: $blue-7d5 !important;
        }

        &-09a {
            background-color: $blue-09a !important;
        }

        &-bc5 {
            background-color: $blue-bc5 !important;
        }

        &-099 {
            background-color: $blue-099 !important;
        }

        &-669 {
            background-color: $blue-669 !important;
        }

    }

    &-purple,
    &-grimace {
        &-88b {
            background-color: $purple-88b !important;
        }

        &-c43 {
            background-color: $purple-c43 !important;
        }

        &-f70 {
            background-color: $purple-f70 !important;
        }

    }

    &-white {
        &-1ee {
            background-color: $white-1ee !important;
        }

        &-fff {
            background-color: $white-fff !important;
        }

    }

    &-black {
        &-c1c {
            background-color: $black-c1c !important;
        }

    }
}