figcaption.overlay {
  bottom: 0;
  color: $color-absolute-white;
  float: left;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  padding: 1.5rem;
  position: absolute;
  text-align: left;
  z-index: 2;
}

figure {
  position: relative;
}