
/* Defines a "section" of a container
 *
 * commonly used as 
 * <div class="container">
 *   <div class="row">
 *     <div class="section"
 *     </div>
 *   </div>
 * </div>
 */

 $section-padding-default: 5rem;


 $section-padding-small: 3.5rem;
 $section-padding-medium: 6.25rem;
 $section-padding-large: 8.5rem;


.section{
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include media-breakpoint-down(md) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  &.pt-none {
    padding-top: 0;
    
    @include media-breakpoint-down(md) {
      padding-top: 0rem;
    }
  }

  &.pb-none {
    padding-bottom: 0;

    @include media-breakpoint-down(md) {
      padding-bottom: 0rem;
    }
  }

  &.pt-sm{
    padding-top: $section-padding-small;

    @include media-breakpoint-down(md) {
      padding-top: ($section-padding-small * .5);
    }
  }

  &.pb-sm{
    padding-bottom: $section-padding-small;

    @include media-breakpoint-down(md) {
      padding-bottom: ($section-padding-small * .5);
    }
  }

  &.pt-md{
    padding-top: $section-padding-medium;

    @include media-breakpoint-down(md) {
      padding-top: ($section-padding-medium * .65);
    }
  }

  &.pb-md{
    padding-bottom: $section-padding-medium;

    @include media-breakpoint-down(md) {
      padding-bottom: ($section-padding-medium * .65);
    }
  }

  &.pt-lg{
    padding-top: $section-padding-large;

    @include media-breakpoint-down(md) {
      padding-top: ($section-padding-large * .5);
    }
  }

  &.pb-lg{
    padding-bottom: $section-padding-large;

    @include media-breakpoint-down(md) {
      padding-top: ($section-padding-large * .5);
    }
  }
}