@media (min-width: 768px) {
    .search-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #search-field {
        width: 80%;
        padding-left: 1em;
    }

    #search-button {
        width: 15%;
    }
}

@media (max-width: 767px) {
    .search-bar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #search-field {
        width: 100%;
        margin-bottom: 1em;
        padding: 1em;
    }

    #search-button {
        width: 100%;
    }
}