.toggle-pill {
    .toggle-pill-button {
        width: 209px;
        height: 50px;
        border-radius: 30px;
        border: 1px solid $color-frost-200;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: $color-frost-200;
        background-color: $color-absolute-white;
    }

    .mini-pill-button {
        width: 150px;
        font-size: 16px;
    }

    .active {
        z-index: 1000;
        background-color: $color-frost-200;
        color: white;

    }
}