.account {
  height: 100%; //92%
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.17);

  .account-title {
    background-color: $color-n-100;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    h2 {
      font-weight: bold;
      font-size: 1rem;
      line-height: 125%;
      width: 100%;
      text-align: center;
    }
  }

  .account-details {
    padding: 1.5rem;
    height: 100%;
  }

  .account-service-charge {
    text-align: center;
    margin-bottom: 2.5rem;
    
    .account-cost {
      font-size: 2.125rem;
      font-weight: bold;
      line-height: 1.5rem;
      padding-right: 0.1875rem;
    }
    p {
      font-size: 0.875rem;
      line-height: 110%;
    }
    a {
      display: block;
      font-weight: bold;
      font-size: 1rem;
      width: 100%;
      height: 2rem;
      line-height: 125%;
    }
  }

  .account-list-features {
    padding-bottom: 1.5rem;

    .fa-li {
      background-color: $color-absolute-white;
      width: 1rem;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      height: 1rem;
      left: -1.5rem;
    }

    li {
      position: relative;
      color: $color-frost-200;
      padding-bottom: 8px;
    }

    li::before{
      content:"";
      position: absolute;
      left: -17px; /* adjust manually */
      border-left: 1px solid $color-a-100;
      height: 100%;
      width: 1px;
    }

    &.personal-features{
      li::before {
        border-left: none;
      }
    }

    li:last-child::before {
      border-left: none;
    }

    .fa-circle {
      background-color: $color-absolute-white;
      font-size: 4px;//Must be px
    }
  }
}

.accounts-carousel {
  min-height: 55rem;
}

.account-under-25 {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: $color-a-100;
  width: 100%;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;

  @include media-breakpoint-between(md, lg) {
    top: -4.95rem;
  }

  @media (max-width: 362px) {
    top: -4.95rem;
  }

  a {
    color: $color-b-400;
    text-decoration: none;
  }
  
  p {
    color: $color-b-400;
    font-size: 1rem;
    line-height: 125%;
    text-align: center;
    width: 100%;
  }
}