/* SECONDARY COLOR SETS
  All of the color sets are listed from lightest to darkest.
  The correct naming covention is as follows:

  for scss ->
    $color-colorabbreviation-colorshade: hexcode
  for class name ->
    .frost-colortype-colorname-colorshade {csshere} //where colortype is background-color or color
  */

// pamplemousse color calls
$color-p-100: #FFF0E9;
$color-p-200: #FADFD0;
$color-p-300: #F8CDB7;
$color-p-400: #F39880;
$color-p-500: #C3705E;
$color-p-600: #A4493D;
$color-p-700: #823B34;
$color-p-800: #572D2D;

// tangerine color calls
$color-t-100: #FEF2E1;
$color-t-200: #FEE4CD;
$color-t-300: #FDD1A9;
$color-t-400: #FDBE87;
$color-t-500: #F8AD6D;
$color-t-600: #D3832B;
$color-t-700: #B36924;
$color-t-800: #934D11;

// yellow color calls
$color-y-100: #FFF8D9;
$color-y-200: #FFF5C9;
$color-y-300: #FEEDB0;
$color-y-400: #FADB85;
$color-y-500: #F6BE4D;
$color-y-600: #E0A526;

// fern color calls
$color-f-100: #DDFAEF;
$color-f-200: #C2EFE7;
$color-f-300: #9AE2D4;
$color-f-400: #62D3BD;
$color-f-500: #00B097;
$color-f-600: #00957A;
$color-f-700: #00816D;
$color-f-800: #285C4D;

// azure color calls
$color-a-100: #D7F0FB;
$color-a-200: #AAD8F2;
$color-a-300: #7AC2EA;
$color-a-400: #4BA8E2;
$color-a-500: #0067B9;
$color-a-600: #004EA8;

.color {
    &-p {
        &-100 {
            color: $color-p-100 !important;
        }

        &-200 {
            color: $color-p-200 !important;
        }

        &-300 {
            color: $color-p-300 !important;
        }

        &-400 {
            color: $color-p-400 !important;
        }

        &-500 {
            color: $color-p-500 !important;
        }

        &-600 {
            color: $color-p-600 !important;
        }

        &-700 {
            color: $color-p-700 !important;
        }

        &-800 {
            color: $color-p-800 !important;
        }
    }

    &-t {
        &-100 {
            color: $color-t-100 !important;
        }

        &-200 {
            color: $color-t-200 !important;
        }

        &-300 {
            color: $color-t-300 !important;
        }

        &-400 {
            color: $color-t-400 !important;
        }

        &-500 {
            color: $color-t-500 !important;
        }

        &-600 {
            color: $color-t-600 !important;
        }

        &-700 {
            color: $color-t-700 !important;
        }

        &-800 {
            color: $color-t-800 !important;
        }
    }

    &-y {
        &-100 {
            color: $color-y-100 !important;
        }

        &-200 {
            color: $color-y-200 !important;
        }

        &-300 {
            color: $color-y-300 !important;
        }

        &-400 {
            color: $color-y-400 !important;
        }

        &-500 {
            color: $color-y-500 !important;
        }

        &-600 {
            color: $color-y-600 !important;
        }
    }

    &-f {
        &-100 {
            color: $color-f-100 !important;
        }

        &-200 {
            color: $color-f-200 !important;
        }

        &-300 {
            color: $color-f-300 !important;
        }

        &-400 {
            color: $color-f-400 !important;
        }

        &-500 {
            color: $color-f-500 !important;
        }

        &-600 {
            color: $color-f-600 !important;
        }

        &-700 {
            color: $color-f-700 !important;
        }

        &-800 {
            color: $color-f-800 !important;
        }
    }

    &-a {
        &-100 {
            color: $color-a-100 !important;
        }

        &-200 {
            color: $color-a-200 !important;
        }

        &-300 {
            color: $color-a-300 !important;
        }

        &-400 {
            color: $color-a-400 !important;
        }

        &-500 {
            color: $color-a-500 !important;
        }

        &-600 {
            color: $color-a-600 !important;
        }
    }
}

.bkgcolor {
    &-p {
        &-100 {
            background-color: $color-p-100 !important;
        }

        &-200 {
            background-color: $color-p-200 !important;
        }

        &-300 {
            background-color: $color-p-300 !important;
        }

        &-400 {
            background-color: $color-p-400 !important;
        }

        &-500 {
            background-color: $color-p-500 !important;
        }

        &-600 {
            background-color: $color-p-600 !important;
        }

        &-700 {
            background-color: $color-p-700 !important;
        }

        &-800 {
            background-color: $color-p-800 !important;
        }
    }

    &-t {
        &-100 {
            background-color: $color-t-100 !important;
        }

        &-200 {
            background-color: $color-t-200 !important;
        }

        &-300 {
            background-color: $color-t-300 !important;
        }

        &-400 {
            background-color: $color-t-400 !important;
        }

        &-500 {
            background-color: $color-t-500 !important;
        }

        &-600 {
            background-color: $color-t-600 !important;
        }

        &-700 {
            background-color: $color-t-700 !important;
        }

        &-800 {
            background-color: $color-t-800 !important;
        }
    }

    &-y {
        &-100 {
            background-color: $color-y-100 !important;
        }

        &-200 {
            background-color: $color-y-200 !important;
        }

        &-300 {
            background-color: $color-y-300 !important;
        }

        &-400 {
            background-color: $color-y-400 !important;
        }

        &-500 {
            background-color: $color-y-500 !important;
        }

        &-600 {
            background-color: $color-y-600 !important;
        }
    }

    &-f {
        &-100 {
            background-color: $color-f-100 !important;
        }

        &-200 {
            background-color: $color-f-200 !important;
        }

        &-300 {
            background-color: $color-f-300 !important;
        }

        &-400 {
            background-color: $color-f-400 !important;
        }

        &-500 {
            background-color: $color-f-500 !important;
        }

        &-600 {
            background-color: $color-f-600 !important;
        }

        &-700 {
            background-color: $color-f-700 !important;
        }

        &-800 {
            background-color: $color-f-800 !important;
        }
    }

    &-a {
        &-100 {
            background-color: $color-a-100 !important;
        }

        &-200 {
            background-color: $color-a-200 !important;
        }

        &-300 {
            background-color: $color-a-300 !important;
        }

        &-400 {
            background-color: $color-a-400 !important;
        }

        &-500 {
            background-color: $color-a-500 !important;
        }

        &-600 {
            background-color: $color-a-600 !important;
        }
    }
}
