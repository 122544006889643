.swiper-container{
  // width: 100%;
  height: 100%;
  min-height: 9.5rem;
  overflow-x: hidden;
  overflow-y: visible;
}

.swiper-slide {
  height: auto;
  width: 80%;
  @include media-breakpoint-down (md) {
    text-align: left;
  }
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

.swiper-child {
  margin: auto;
}

.swiper-controls,
.swiper-controls.light-theme {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      height: 0.625rem;
      width: 0.625rem;
      background-color: $color-n-500;
      margin-left: 0.5625rem;
      margin-right: 0.5625rem;

      &.swiper-pagination-bullet-active {
        background-color: $color-n-600;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev-style,
  .swiper-button-next-style {
    position: unset;
    color: $color-frost-200;
    font-weight: 300;
    font-size: 2.5rem;
    cursor: pointer;

    &.swiper-button-disabled,
    &.swiper-button-disabled-style {
      color: $color-n-500 !important;
      opacity: 0.6;
    }
  }

  .swiper-button-prev,
  .swiper-button-prev-style {
    margin-right: 1.2812rem;
    &:after {
      content: url("~Images/icons/left-arrow.svg");
    }
  }

  .swiper-button-next,
  .swiper-button-next-style {
    margin-left: 1.2812rem;
    &:after {
      content: url("~Images/icons/right-arrow.svg");
    }
  }
}

.swiper-controls.dark-theme,
.dark-theme .swiper-controls {

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev-style,
  .swiper-button-next-style {
    color: $color-absolute-white; 
  }

  .swiper-button-disabled,
  .swiper-button-disabled-style {
    opacity: .5 !important;
  }

  // These are specific for specificity (So they override the default classes)
  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: $color-absolute-white;
      opacity: .5;
    }
  
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: $color-absolute-white;
      opacity: 1;
    }
  }
}

.swiper-container.slide-blur {
  .swiper-slide-next,
  .swiper-slide-prev {
    filter: blur(2.75px);
    opacity: .6;
  }
}

.swiper-container.carousel-type-3 {
  .swiper-slide {
    width: 46%;


    @include media-breakpoint-down(md) {
      width: 80%;
    }
  }
}
