.text-preset {
    &-1,
    &-2,
    &-3,
    &-4,
    &-5,
    &-6,
    &-7,
    &-8 {
        font-weight: normal;

        &-semibold,
        strong {
            font-weight: bold;
        }
    }

    &-1,
    &-1-semibold,
    &-1 p,
    &-1 span {
        font-size: 3.25rem;
        line-height: 3.625rem;

        @include media-breakpoint-down(sm) {
            font-size: 2rem;
            line-height: 2.25rem;
        }
    }

    &-2,
    &-2-semibold,
    &-2 p,
    &-2 span {
        font-size: 2rem;
        line-height: 2.25rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    &-3,
    &-3-semibold,
    &-3 p,
    &-3 span {
        font-size: 1.5rem;
        line-height: 1.75rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }
    }

    &-4,
    &-4-semibold,
    &-4 p,
    &-4 span {
        font-size: 1rem;
        line-height: 1.25rem;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &-5,
    &-5-semibold,
    &-5 p,
    &-5 span {
        font-size: 1.125rem;
        line-height: 1.5rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }

    &-6,
    &-6-semibold,
    &-6 p,
    &-6 span {
        font-size: 0.875rem;
        line-height: 1.125rem;
    }

    &-7,
    &-7-semibold,
    &-7 p,
    &-7 span {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    &-8,
    &-8-semibold,
    &-8 p,
    &-8 span {
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
    }
}

strong {
    .text-preset {
        &-1,
        &-2,
        &-3,
        &-4,
        &-5,
        &-6,
        &-7,
        &-8 {
            font-weight: bold;
        }
    }
}