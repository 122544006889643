.switch-darkmode{
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked {
      + label:before {
        color: $color-absolute-white;
        border-color: $color-absolute-white;
        background-color: $color-i-800;
      }

      + label:after {
        background-color: $color-absolute-white;
        color: $color-frost-200;
        transform: translateX(1.4rem);
        content: "\f186";
        font-weight: 900;
      }
    }
  }

  input:focus + label {
    border: $color-frost-200 solid 1px;
  }

  label {
    position: relative;
    &:before {
      width: 3.125rem;
      height: 1.75rem;
      pointer-events: all;
      border-radius: 6.25rem;
      display: block;
      content: "";
      border: $color-frost-200 solid 1px;
      transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    &:after {
      position: absolute;
      color: $color-absolute-white;
      // background-image: url("/dam/Images/icons/sun.svg");
      background-repeat: no-repeat;
      background-position: center;
      font-family: "Font Awesome 6 Pro";
      content: "";
      text-align: center;
      --fa-secondary-color: $color-absolute-white;
      line-height: 1.375rem;
      top: 3px;
      left: 3px;
      width: 1.375rem;
      height: 1.375rem;
      background-color: $color-frost-200;
      border-radius: 1rem;
      transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
      transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    }
  }
}


.feature-switch-darkmode{
  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.feature-darkmode-content{
  position: relative;
  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}