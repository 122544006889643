/**
  * A table with two headers, following the guide hre
  * https://www.w3.org/WAI/tutorials/tables/two-headers/
 **/

table.two-header-table {
  width: 100%;
  font-weight: bold;
  font-size: 0.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  td.empty,
  th.empty {
    background: $color-absolute-white;
  }

  &.promote-first {
    td:nth-child(2) {
      background-color: $color-frost-200;
      color: $color-absolute-white;
    }
  }

  .promo{
    background-color: $color-frost-200;
    color: $color-absolute-white;
  }

  tr {
    border-bottom: 0.3125rem solid $color-absolute-white;
    line-height: 2.2rem;

    &:first-child th {
      line-height: 1em;
      height: 3.575rem;
      font-size: 1rem;
    } 

    background-color: $color-n-100;
    &:nth-child(even){
      background-color: $color-n-200;
    }

    &.break-row {
      border-bottom-width: 1.2rem;
    }

    th,
    td {
      @include media-breakpoint-up(sm) {
        padding: 0.5rem;
      }
      text-align: center;
      vertical-align: middle;

      &:not(:first-child):not(:last-child) {
        border-left: 0.375rem solid $color-absolute-white;
        border-right: 0.375rem solid $color-absolute-white;
      }
    }

    th {

      .sub-header{
        font-size: 0.6rem;
        font-weight: 400;
        line-height: 1em;
      }
    }
  }
}
