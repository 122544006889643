    .tabbed-navigation {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        width: fit-content;
        border-radius: 28px;
        text-align: center;
        white-space: nowrap;
        overflow: auto;
        flex-wrap: wrap;
        border: 1px solid #E0E0E0;
        padding: 5px;
    }

    .tab {
        display: inline-flex;
        position: relative;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;
        border: none;
        border-radius: 2.5rem;
        background: inherit;
        line-height: 2.75rem;
        text-decoration: none;
        text-decoration: initial;
        cursor: pointer;
        vertical-align: middle;

        @include media-breakpoint-down(sm) {
            padding: 0 0.625rem;
            font-size: 0.8125rem;
        }
    }

    .tab.active .tab-label {
        color: white;
        font-size: 15px;
    }

    .tab .tab-label {
        transition: font-variation-settings .2s cubic-bezier(.2, 0, 0, 1);
        font-variation-settings: "GRAD" 0;
        z-index: 1;
        font-weight: 700;
    }

    .tab.active:before {
        opacity: 1;
        transform: scaleX(1);
        background: #8a488b;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    }

    .tab:before {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: scaleX(.32);
        transition-duration: .2s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(.2, 0, 0, 1);
        border-radius: 40px;
        background: #ffdad9;
        content: "";
    }

    .tab:hover {
        background: linear-gradient(0deg, rgba(83, 67, 67, .08), rgba(83, 67, 67, .08));
    }

    .tab:focus-visible {
        margin: 0;
        margin: initial;
        padding: 0 22px;
        border: 2px solid #211a1a;
        outline: 0;
        box-shadow: inset 0 0 0 2px #fcfcfc;
    }